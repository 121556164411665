export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any };
};

/** Represents an account (a company) */
export type Account = {
  __typename?: 'Account';
  /** Alert subscriptions for the account */
  alertSubscriptions: Array<AlertSubscription>;
  /** A count of all BinBars associated with the account */
  binBarCount: Scalars['Int']['output'];
  /** A list of all BinBars associated with the account */
  binBars: Array<BinBar>;
  /** A count of all container sites associated with the account */
  containerSiteCount: Scalars['Int']['output'];
  /** A list of all container sites associated with the account */
  containerSites: Array<ContainerSite>;
  /** Document processors for the account */
  documentProcessors: Array<DocumentProcessor>;
  /** Id of the account */
  id: Scalars['ID']['output'];
  /** Name of the account */
  name: Scalars['String']['output'];
  /** Parent account of the account */
  parentAccount?: Maybe<Account>;
  /** Salesforce Id for the account */
  salesforceId?: Maybe<Scalars['String']['output']>;
  /** A count of all service locations associated with the account */
  serviceLocationCount: Scalars['Int']['output'];
  /** A list of all service locations associated with the account */
  serviceLocations: Array<ServiceLocation>;
  /** Slack webhook URL for account alerts */
  slackWebhookUrl?: Maybe<Scalars['String']['output']>;
  /** A count of all users associated with the account */
  userCount: Scalars['Int']['output'];
  /** A list of all users associated with the account */
  users: Array<User>;
};

/** Represents an account (a company) */
export type AccountBinBarsArgs = {
  page?: PaginationInput;
};

/** Represents an account (a company) */
export type AccountContainerSitesArgs = {
  page?: PaginationInput;
};

/** Represents an account (a company) */
export type AccountServiceLocationsArgs = {
  page?: PaginationInput;
};

/** Represents an account (a company) */
export type AccountUsersArgs = {
  page?: PaginationInput;
};

/** Data associated with the account assigned event */
export type AccountAssignedEventData = {
  __typename?: 'AccountAssignedEventData';
  /** Account that the BinBar was assigned to */
  newAccount: Account;
};

/** Represents user account roles */
export enum AccountRole {
  /** Administrator */
  Administrator = 'ADMINISTRATOR',
  Driver = 'DRIVER',
  /** Basic user */
  User = 'USER',
  /** View only user */
  Viewer = 'VIEWER',
}

/** Represents the fields available to sort accounts */
export enum AccountSort {
  /** Name */
  Name = 'NAME',
  /** Parent account name */
  ParentName = 'PARENT_NAME',
}

/** Input to sort results of a query */
export type AccountSortInput = {
  /** Field to sort on */
  sortBy: AccountSort;
  /** Direction for the sort */
  sortDirection: SortDirection;
};

/** Data associated with the account unassigned event */
export type AccountUnassignedEventData = {
  __typename?: 'AccountUnassignedEventData';
  /** Account that the BinBar was unassigned from */
  previousAccount: Account;
};

/** Represents options for different weight adjustment factors */
export enum AdjustmentFactorName {
  /** High average weight, low average load frequency */
  HighAvgWeightLowAvgLoadFreq = 'HIGH_AVG_WEIGHT_LOW_AVG_LOAD_FREQ',
  /** High total weight, high cycle count, low resting, low weight */
  HighTotalWeightHighCycleCtLowRestingLowWeight = 'HIGH_TOTAL_WEIGHT_HIGH_CYCLE_CT_LOW_RESTING_LOW_WEIGHT',
  /** High total weight, mid cycle count, low resting, low weight */
  HighTotalWeightMidCycleCtLowRestingLowWeight = 'HIGH_TOTAL_WEIGHT_MID_CYCLE_CT_LOW_RESTING_LOW_WEIGHT',
  /** Low weight, long duration on site */
  LowWeightLongDurationOnSite = 'LOW_WEIGHT_LONG_DURATION_ON_SITE',
  /** Pre-filled, short duration on site */
  PreFilledShortDurationOnSite = 'PRE_FILLED_SHORT_DURATION_ON_SITE',
  /** Weight adjustment */
  WeightAdjustment = 'WEIGHT_ADJUSTMENT',
}

/** Represents an alert */
export type Alert = {
  __typename?: 'Alert';
  /** Account associated with the alert */
  account?: Maybe<Account>;
  /** Additional data associated with the alert */
  alertData?: Maybe<AlertData>;
  /** Container site associated with the alert */
  containerSite?: Maybe<ContainerSite>;
  /** Id of the alert */
  id: Scalars['ID']['output'];
  /** List of notifications associated with the alert */
  notifications: Array<AlertNotification>;
  /** Service location associated with the alert */
  serviceLocation?: Maybe<ServiceLocation>;
  /** Date and time of the alert */
  timestamp: Scalars['DateTimeISO']['output'];
  /** Type of alert */
  type: AlertType;
};

export type AlertData =
  | ContainerSiteLowConnectivityAlertData
  | ContainerSiteOfflineAlertData
  | MissingWeightTicketsAlertData;

/** Represents an alert notification */
export type AlertNotification = {
  __typename?: 'AlertNotification';
  /** Account assigned to the alert */
  account?: Maybe<Account>;
  /** Whether the alert has been acknowledged */
  acknowledged: Scalars['Boolean']['output'];
  /** The date and time at which the email was sent */
  emailSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Whether an email should be sent */
  sendEmail: Scalars['Boolean']['output'];
  /** Whether a Slack notification should be sent */
  sendSlack: Scalars['Boolean']['output'];
  /** Whether a SMS should be sent */
  sendSms: Scalars['Boolean']['output'];
  /** The date and time at which the Slack notification was sent */
  slackSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  /** The date and time at which the SMS was sent */
  smsSentAt?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Type of alert notification */
  type: AlertNotificationType;
  /** User assigned to the alert */
  user?: Maybe<User>;
};

/** Represents the type of an alert notification */
export enum AlertNotificationType {
  /** External alert */
  External = 'EXTERNAL',
  /** Internal alert */
  Internal = 'INTERNAL',
}

/** Represents the fields available to sort alerts */
export enum AlertSort {
  /** Account Name */
  AccountName = 'ACCOUNT_NAME',
  /** Container Site */
  ContainerSite = 'CONTAINER_SITE',
  /** Date */
  Date = 'DATE',
  /** Service Location */
  ServiceLocation = 'SERVICE_LOCATION',
  /** Type */
  Type = 'TYPE',
}

/** Input to sort results of a query */
export type AlertSortInput = {
  /** Field to sort on */
  sortBy: AlertSort;
  /** Direction for the sort */
  sortDirection: SortDirection;
};

/** Alert subscription for a user or account */
export type AlertSubscription = {
  __typename?: 'AlertSubscription';
  /** The associated account */
  account?: Maybe<Account>;
  /** The type of alert */
  alertType: AlertType;
  /** The associated container site */
  containerSite?: Maybe<ContainerSite>;
  /** Id of the alert subscription */
  id: Scalars['ID']['output'];
  /** Should an email be sent */
  sendEmail: Scalars['Boolean']['output'];
  /** Should a Slack notification be sent */
  sendSlack: Scalars['Boolean']['output'];
  /** Should a SMS message be sent */
  sendSms: Scalars['Boolean']['output'];
  /** The associated service location */
  serviceLocation?: Maybe<ServiceLocation>;
  /** The subscriber */
  subscriber: Subscriber;
};

/** Input for an alert subscription */
export type AlertSubscriptionInput = {
  /** Associated account id */
  accountId?: InputMaybe<Scalars['ID']['input']>;
  /** Type of alert */
  alertType: AlertType;
  /** Associated container site id */
  containerSiteId?: InputMaybe<Scalars['ID']['input']>;
  /** Should an email be sent */
  sendEmail: Scalars['Boolean']['input'];
  /** Should a Slack notification be sent */
  sendSlack: Scalars['Boolean']['input'];
  /** Should a SMS message be sent */
  sendSms: Scalars['Boolean']['input'];
  /** Associated service location id */
  serviceLocationId?: InputMaybe<Scalars['ID']['input']>;
};

/** Represents the type of an alert */
export enum AlertType {
  BinbarLowBattery = 'BINBAR_LOW_BATTERY',
  ContainerSite_80PercentFull = 'CONTAINER_SITE_80_PERCENT_FULL',
  ContainerSite_100PercentFull = 'CONTAINER_SITE_100_PERCENT_FULL',
  ContainerSiteLowConnectivity = 'CONTAINER_SITE_LOW_CONNECTIVITY',
  /** Alert triggered when a container site goes offline */
  ContainerSiteOffline = 'CONTAINER_SITE_OFFLINE',
  MissingWeightTickets = 'MISSING_WEIGHT_TICKETS',
}

/** Represents the Atlas algorithm */
export type AtlasAlgo = {
  __typename?: 'AtlasAlgo';
  encoder0Override?: Maybe<Scalars['Float']['output']>;
  encoder1Override?: Maybe<Scalars['Float']['output']>;
  zNaught: Scalars['Float']['output'];
};

/** Represents the Bacon algorithm */
export type BaconAlgo = {
  __typename?: 'BaconAlgo';
  bumperCalibration: FourthOrderSystem;
  encoder0Calibration: SecondOrderSystem;
  encoder1Calibration: SecondOrderSystem;
  zNaught: Scalars['Float']['output'];
};

/** Represents a BinBar. A BinBar is a tool used to accurately measure the weight inside of a container */
export type BinBar = {
  __typename?: 'BinBar';
  /** Absolute error percentage */
  absoluteErrorPercentage: Scalars['Float']['output'];
  /** Account for the BinBar */
  account?: Maybe<Account>;
  /** Algorithm used by the BinBar to measure force */
  algorithm: ForceAlgo;
  /** Date algorithm was last updated */
  algorithmUpdatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Average error percentage */
  averageErrorPercentage: Scalars['Float']['output'];
  /** Battery level of the BinBar. This can be used to indicate when a battery needs to be replaced */
  batteryLevel?: Maybe<Scalars['Float']['output']>;
  /** Brain id of the BinBar */
  brainId: Scalars['String']['output'];
  /** Total cycles for the BinBar */
  containerCycleCount: Scalars['Int']['output'];
  /** Total cycles with weight tickets for the BinBar */
  containerCycleWithTicketCount: Scalars['Int']['output'];
  /** Container site associated with the BinBar */
  containerSite?: Maybe<ContainerSite>;
  /** Total container sites assigned for the BinBar */
  containerSiteCount: Scalars['Int']['output'];
  /** A list of diagnostics that were reported by the BinBar */
  diagnosticPosts: Array<Diagnostic>;
  /** Ebox id of the BinBar */
  eboxId?: Maybe<Scalars['String']['output']>;
  /** Events that have occurred for the BinBar */
  events: Array<BinBarEvent>;
  /** Firmware configuration for the BinBar */
  firmwareConfig: FirmwareConfig;
  /** Id of the BinBar */
  id: Scalars['ID']['output'];
  /** Last diagnostic that was reported by the BinBar */
  lastDiagnostic?: Maybe<Diagnostic>;
  /** Last weight that the BinBar measured. This value shows how up to date the weight value of the BinBar is and the weight value */
  lastForce?: Maybe<ForcePost>;
  /** Last location that was reported by the BinBar */
  lastLocation?: Maybe<CellularLocation>;
  /** Last date and time that the BinBar was synced */
  lastSync?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Ledger instance */
  ledgerInstance?: Maybe<LedgerInstance>;
  /** Name of the BinBar */
  name: Scalars['String']['output'];
  /** Notes for the BinBar */
  notes?: Maybe<Scalars['String']['output']>;
  /** PCB id of the BinBar */
  pcbId?: Maybe<Scalars['String']['output']>;
  /** Signal quality of the BinBar. This value represents how much interference there is in the signal */
  signalQuality?: Maybe<Scalars['Float']['output']>;
  /** Signal strength of the BinBar. This value represents the signal speed and reliability */
  signalStrength?: Maybe<Scalars['Float']['output']>;
  /** Status of the BinBar */
  status: BinBarStatus;
};

/** Represents a BinBar. A BinBar is a tool used to accurately measure the weight inside of a container */
export type BinBarDiagnosticPostsArgs = {
  range?: InputMaybe<DateRangeInput>;
};

/** Represents an algorithm for a BinBar at a container site */
export type BinBarAlgorithm = {
  __typename?: 'BinBarAlgorithm';
  /** The algorithm used to calculate weight at this container site */
  algorithm: WeightAlgo;
  /** BinBar associated with this algorithm */
  binBar: BinBar;
};

/** Represents a BinBar event.  For example when a BinBar is assigned or unassigned to an account */
export type BinBarEvent = {
  __typename?: 'BinBarEvent';
  /** Associated BinBar */
  binBar: BinBar;
  /** The date and time at which the event was triggered */
  createdAt: Scalars['DateTimeISO']['output'];
  /** The user who triggered the event */
  createdBy?: Maybe<User>;
  /** Type of event for the BinBar */
  event: BinBarEventType;
  /** Additional data associated with the event */
  eventData?: Maybe<BinBarEventData>;
  /** Id of the BinBar event */
  id: Scalars['ID']['output'];
};

export type BinBarEventData =
  | AccountAssignedEventData
  | AccountUnassignedEventData
  | ContainerCycleEndedEventData
  | ContainerSiteAssignedEventData
  | ContainerSiteUnassignedEventData
  | NotesChangedEventData
  | StatusChangedEventData;

/** Represents the type of event for a BinBar */
export enum BinBarEventType {
  /** BinBar was assigned to an account */
  AccountAssigned = 'ACCOUNT_ASSIGNED',
  /** BinBar was unassigned from an account */
  AccountUnassigned = 'ACCOUNT_UNASSIGNED',
  /** BinBar was configured */
  Configured = 'CONFIGURED',
  /** Container cycle ended that was associated with a BinBar */
  ContainerCycleEnded = 'CONTAINER_CYCLE_ENDED',
  /** BinBar was assigned to a container site */
  ContainerSiteAssigned = 'CONTAINER_SITE_ASSIGNED',
  /** BinBar was unassigned from a container site */
  ContainerSiteUnassigned = 'CONTAINER_SITE_UNASSIGNED',
  /** BinBar was created */
  Created = 'CREATED',
  NotesChanged = 'NOTES_CHANGED',
  StatusChanged = 'STATUS_CHANGED',
}

/** Represents the fields available to sort BinBars */
export enum BinBarSort {
  /** Account name */
  AccountName = 'ACCOUNT_NAME',
  /** Battery level */
  BatteryLevel = 'BATTERY_LEVEL',
  /** Cellular quality */
  CellularQuality = 'CELLULAR_QUALITY',
  /** Cellular strength */
  CellularStrength = 'CELLULAR_STRENGTH',
  /** Firmware version */
  FirmwareVersion = 'FIRMWARE_VERSION',
  /** Last synced at date */
  LastSyncedAt = 'LAST_SYNCED_AT',
  /** Name */
  Name = 'NAME',
  /** Status */
  Status = 'STATUS',
}

/** Input to sort results of a query */
export type BinBarSortInput = {
  /** Field to sort on */
  sortBy: BinBarSort;
  /** Direction for the sort */
  sortDirection: SortDirection;
};

/** Deployment status of a BinBar */
export enum BinBarStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Awaiting calibration */
  AwaitingCalibration = 'AWAITING_CALIBRATION',
  /** Awaiting shipment */
  AwaitingShipment = 'AWAITING_SHIPMENT',
  /** Decommissioned */
  Decommissioned = 'DECOMMISSIONED',
  Recalled = 'RECALLED',
  /** Reclaimed */
  Reclaimed = 'RECLAIMED',
}

export type CalibrationDetails = {
  __typename?: 'CalibrationDetails';
  bumperRmsError?: Maybe<Scalars['Float']['output']>;
  configName?: Maybe<Scalars['String']['output']>;
  extractionName?: Maybe<Scalars['String']['output']>;
  inSpecMaxRepeatedVariation?: Maybe<Scalars['Float']['output']>;
  inSpecRepeatedStdError?: Maybe<Scalars['Float']['output']>;
  maxRepeatedVariation?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  repeatedStdError?: Maybe<Scalars['Float']['output']>;
  rmsError?: Maybe<Scalars['Float']['output']>;
  runTimestamp?: Maybe<Scalars['DateTimeISO']['output']>;
  testName?: Maybe<Scalars['String']['output']>;
};

export type CalibrationDetailsInput = {
  bumperRmsError?: InputMaybe<Scalars['Float']['input']>;
  configName?: InputMaybe<Scalars['String']['input']>;
  extractionName?: InputMaybe<Scalars['String']['input']>;
  inSpecMaxRepeatedVariation?: InputMaybe<Scalars['Float']['input']>;
  inSpecRepeatedStdError?: InputMaybe<Scalars['Float']['input']>;
  maxRepeatedVariation?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  repeatedStdError?: InputMaybe<Scalars['Float']['input']>;
  rmsError?: InputMaybe<Scalars['Float']['input']>;
  runTimestamp?: InputMaybe<Scalars['DateTimeISO']['input']>;
  testName?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a geographical area */
export type CellularLocation = {
  __typename?: 'CellularLocation';
  /** Latitude of the center of the accuracy radius */
  latitude: Scalars['Float']['output'];
  /** Longitude of the center of the accuracy radius */
  longitude: Scalars['Float']['output'];
  /** Accuracy radius in meters of the latitude and longitude values */
  radius: Scalars['Float']['output'];
};

/** Represents a file in cloud storage */
export type CloudStorageFile = {
  __typename?: 'CloudStorageFile';
  /** The date the file was uploaded */
  createdAt: Scalars['DateTimeISO']['output'];
  /** Signed uri for downloading a file */
  downloadUri?: Maybe<Scalars['String']['output']>;
  /** Id of the file */
  id: Scalars['ID']['output'];
  /** The upload status of the file */
  status: UploadStatus;
  /** The user who uploaded the file */
  uploadedBy: User;
};

/** Represents a cloud storage file upload container */
export type CloudStorageFileUpload = {
  __typename?: 'CloudStorageFileUpload';
  /** Id of the file */
  id: Scalars['ID']['output'];
  /** Signed uri for uploading a file */
  uploadUri: Scalars['String']['output'];
};

/** Represents a user's service location and container site access for an account */
export type ContainerAccess = {
  __typename?: 'ContainerAccess';
  /** The account for the container access policy */
  account: Account;
  /** The service locations for the container access policy */
  serviceLocations?: Maybe<Array<ServiceLocationAccess>>;
};

export type ContainerAccessInput = {
  /** Account for the service locations */
  accountId: Scalars['ID']['input'];
  /** Service location access policies for the account */
  serviceLocations?: InputMaybe<Array<ServiceLocationAccessInput>>;
};

/** Represents a weight cycle of a container, from installation (drop-off) to removal (pickup) */
export type ContainerCycle = {
  __typename?: 'ContainerCycle';
  /** The BinBar that was used for this weight cycle */
  binBar?: Maybe<BinBar>;
  /** The container site that created this weight cycle */
  containerSite: ContainerSite;
  /** Cost per run (if known) */
  costPerRun?: Maybe<CurrencyValue>;
  /** Cost per weight (if known) */
  costPerWeightUnit?: Maybe<CurrencyValue>;
  /** The weight of the container itself (if known) */
  emptyContainerWeight?: Maybe<Scalars['Float']['output']>;
  /** Exclude this cycle from waf calculations and reports */
  excludeInWafAndReports: Scalars['Boolean']['output'];
  /** The final weight of the container, as measured by the BinBar */
  finalWeight?: Maybe<WeightPost>;
  /** Id of the container cycle */
  id: Scalars['ID']['output'];
  /** Installation photos for the container cycle */
  installPhotos: Array<CloudStorageFile>;
  /** The date the container was installed on the BinBar */
  installation: Scalars['DateTimeISO']['output'];
  /** A list of all container loads recorded by the container site during this weight cycle */
  loads: Array<ContainerLoad>;
  /** The date the container was picked up from the BinBar */
  removal?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Removal photos for the container cycle */
  removalPhotos: Array<CloudStorageFile>;
  /** The starting weight of the container, as measured by the BinBar */
  startingWeight?: Maybe<WeightPost>;
  /** If added, a weight ticket for this cycle */
  weightTicket?: Maybe<WeightTicket>;
  /** A list of all weights recorded by the container site during this weight cycle */
  weights: Array<WeightPost>;
};

/** Represents a weight cycle of a container, from installation (drop-off) to removal (pickup) */
export type ContainerCycleCostPerWeightUnitArgs = {
  unit: WeightUnit;
};

/** Represents a weight cycle of a container, from installation (drop-off) to removal (pickup) */
export type ContainerCycleEmptyContainerWeightArgs = {
  unit: WeightUnit;
};

/** Represents a weight cycle of a container, from installation (drop-off) to removal (pickup) */
export type ContainerCycleWeightsArgs = {
  resolution?: Resolution;
};

/** Data associated with the container cycle ended event */
export type ContainerCycleEndedEventData = {
  __typename?: 'ContainerCycleEndedEventData';
  /** Container cycle associated with the event */
  containerCycle: ContainerCycle;
};

/** Represents the fields available to filter container cycles */
export enum ContainerCycleFilter {
  /** Show only cycles with removal date or without removal date */
  HasRemovalDate = 'HAS_REMOVAL_DATE',
  /** Show only cycles with weight tickets or without weight tickets */
  HasWeightTicket = 'HAS_WEIGHT_TICKET',
  /** Show only cycles with install date greater than the value provided */
  InstallDateGreaterThan = 'INSTALL_DATE_GREATER_THAN',
}

/** Input to filter results of a query */
export type ContainerCycleFilterInput = {
  /** Field to filter by */
  filterBy: ContainerCycleFilter;
  /** Value to filter by */
  value: Scalars['String']['input'];
};

/** Represents a load in a container cycle */
export type ContainerLoad = {
  __typename?: 'ContainerLoad';
  /** The container cycle associated with this load */
  containerCycle: ContainerCycle;
  /** The container site associated with this load */
  containerSite: ContainerSite;
  /** Id of the container load */
  id: Scalars['ID']['output'];
  /** The number of the load in the cycle (L0, L1, etc) */
  loadNumberInCycle: Scalars['Int']['output'];
  /** The total weight of the load */
  loadWeight: Scalars['Float']['output'];
  /** The date the loading cycle ended */
  loadingEndDate: Scalars['DateTimeISO']['output'];
  /** The weight point that ended the load cycle */
  loadingEndPoint: WeightPost;
  /** The date the loading cycle began */
  loadingStartDate: Scalars['DateTimeISO']['output'];
  /** The weight point right before the load happened */
  loadingStartPoint: WeightPost;
};

/** Represents a load in a container cycle */
export type ContainerLoadLoadWeightArgs = {
  unit: WeightUnit;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSite = {
  __typename?: 'ContainerSite';
  /** Driver assigned to the container site */
  assignedDriver?: Maybe<User>;
  /** Average business days on site for the container */
  averageBusinessDaysOnSite?: Maybe<Scalars['Float']['output']>;
  /** Average business days on site standard deviation for the container */
  averageBusinessDaysOnSiteStdDev?: Maybe<Scalars['Float']['output']>;
  /** Average days on site for the container */
  averageDaysOnSite?: Maybe<Scalars['Float']['output']>;
  /** Average days on site standard deviation for the container */
  averageDaysOnSiteStdDev?: Maybe<Scalars['Float']['output']>;
  /** Average pickup weight for the container */
  averagePickUpWeight?: Maybe<Scalars['Float']['output']>;
  /** Average pickup weight standard deviation for the container */
  averagePickUpWeightStdDev?: Maybe<Scalars['Float']['output']>;
  /** Battery level of the BinBar at the container site. This can be used to indicate when a battery needs to be replaced */
  batteryLevel?: Maybe<Scalars['Float']['output']>;
  /** A list of all battery reports recorded by the container site */
  batteryPosts: Array<RawPost>;
  /** BinBar linked with the container site */
  binBar?: Maybe<BinBar>;
  /** The algorithms used to calculate weight at this container site */
  binBarAlgorithms: Array<BinBarAlgorithm>;
  /** Commodity type dumped into the container */
  commodityType?: Maybe<Scalars['String']['output']>;
  /** All container cycles for this container site, with the most recent (by install date) first */
  containerCycles: Array<ContainerCycle>;
  /** Count of all container cycles for this container site */
  containerCyclesCount: Scalars['Int']['output'];
  /** Cost per run (if known) */
  costPerRun?: Maybe<CurrencyValue>;
  /** Current cost per weight (if known) */
  costPerWeightUnit?: Maybe<CurrencyValue>;
  /** Current fullness of the container */
  currentFullness?: Maybe<Scalars['Float']['output']>;
  /** Current gross weight of the container */
  currentGrossWeight?: Maybe<Scalars['Float']['output']>;
  /** Current net weight of the container */
  currentNetWeight?: Maybe<Scalars['Float']['output']>;
  /** A list of diagnostics that were reported by attached BinBars */
  diagnosticPosts: Array<Diagnostic>;
  /** The weight of the container itself (if known) */
  emptyContainerWeight?: Maybe<Scalars['Float']['output']>;
  /** Id of the container site */
  id: Scalars['ID']['output'];
  /** Installation photos for the container site */
  installPhotos: Array<CloudStorageFile>;
  /** Last location that was reported */
  lastLocation?: Maybe<CellularLocation>;
  /** Last date and time that the container site was synced */
  lastSync?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Last weight measured at the container site */
  lastWeight?: Maybe<WeightPost>;
  /** Maximum gross weight allowed in the container site */
  maxGrossWeight?: Maybe<Scalars['Float']['output']>;
  /** Maximum net weight allowed in the container site */
  maxNetWeight?: Maybe<Scalars['Float']['output']>;
  /** The name of the container site */
  name: Scalars['String']['output'];
  /** The service location linked with the container site */
  serviceLocation: ServiceLocation;
  /** Signal quality of the BinBar at the container site. This value represents how much interference there is in the signal */
  signalQuality?: Maybe<Scalars['Float']['output']>;
  /** A list of all signal quality reports recorded by the container site */
  signalQualityPosts: Array<RawPost>;
  /** Signal strength of the BinBar at the container site. This value represents the signal speed and reliability */
  signalStrength?: Maybe<Scalars['Float']['output']>;
  /** A list of all signal strength reports recorded by the container site */
  signalStrengthPosts: Array<RawPost>;
  /** Size of the container site in yards. This field is only used to help users keep track of containers */
  size?: Maybe<Scalars['Float']['output']>;
  /** Temperature at the container site */
  temperature?: Maybe<Scalars['Float']['output']>;
  /** A list of all temperature reports recorded by the container site */
  temperaturePosts: Array<RawPost>;
  /** Count of weight tickets associated with this container site */
  weightTicketCount: Scalars['Int']['output'];
  /** A list of all weights recorded by the container site */
  weights: Array<WeightPost>;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteAveragePickUpWeightArgs = {
  unit: WeightUnit;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteAveragePickUpWeightStdDevArgs = {
  unit: WeightUnit;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteBatteryPostsArgs = {
  range?: InputMaybe<DateRangeInput>;
  resolution?: Resolution;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteContainerCyclesArgs = {
  filter?: InputMaybe<Array<ContainerCycleFilterInput>>;
  page?: PageInput;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteCostPerWeightUnitArgs = {
  unit: WeightUnit;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteCurrentGrossWeightArgs = {
  unit: WeightUnit;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteCurrentNetWeightArgs = {
  unit: WeightUnit;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteDiagnosticPostsArgs = {
  range?: InputMaybe<DateRangeInput>;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteEmptyContainerWeightArgs = {
  unit: WeightUnit;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteMaxGrossWeightArgs = {
  unit: WeightUnit;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteMaxNetWeightArgs = {
  unit: WeightUnit;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteSignalQualityPostsArgs = {
  range?: InputMaybe<DateRangeInput>;
  resolution?: Resolution;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteSignalStrengthPostsArgs = {
  range?: InputMaybe<DateRangeInput>;
  resolution?: Resolution;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteTemperaturePostsArgs = {
  range?: InputMaybe<DateRangeInput>;
  resolution?: Resolution;
};

/** Represents a container site. A container site is a site where one container exists. The container at the container site may change but the container site will remain the same */
export type ContainerSiteWeightsArgs = {
  range?: InputMaybe<DateRangeInput>;
  resolution?: Resolution;
};

/** Represents a user's container access */
export type ContainerSiteAccess = {
  __typename?: 'ContainerSiteAccess';
  /** The container site for the container access policy */
  containerSite: ContainerSite;
};

/** Data associated with the container site assigned event */
export type ContainerSiteAssignedEventData = {
  __typename?: 'ContainerSiteAssignedEventData';
  /** Container site that the BinBar was assigned to */
  newContainerSite: ContainerSite;
};

/** Represents the fields available to filter container sites */
export enum ContainerSiteFilter {
  /** Account id */
  AccountId = 'ACCOUNT_ID',
  /** Assigned driver id */
  AssignedDriverId = 'ASSIGNED_DRIVER_ID',
  /** Show only container sites with a BinBar attached */
  HasBinbar = 'HAS_BINBAR',
  /** Service location id */
  ServiceLocationId = 'SERVICE_LOCATION_ID',
}

/** Input to filter results of a query */
export type ContainerSiteFilterInput = {
  /** Field to filter by */
  filterBy: ContainerSiteFilter;
  /** Value to filter by */
  value: Scalars['String']['input'];
};

/** Input to group results of a query */
export type ContainerSiteGroupInput = {
  /** Field to group on */
  groupBy: ContainerSiteSort;
};

/** Data associated with the container site low connectivity alert */
export type ContainerSiteLowConnectivityAlertData = {
  __typename?: 'ContainerSiteLowConnectivityAlertData';
  /** The number of connections made from the container site in the last 24 hours */
  connectionsInLast24Hours: Scalars['Int']['output'];
};

/** Data associated with the container site offline alert */
export type ContainerSiteOfflineAlertData = {
  __typename?: 'ContainerSiteOfflineAlertData';
  /** The date and time at which the container site was last synced */
  lastSyncedAt: Scalars['DateTimeISO']['output'];
};

/** Represents the fields available to sort container sites */
export enum ContainerSiteSort {
  /** Account name */
  AccountName = 'ACCOUNT_NAME',
  /** Battery level */
  BatteryLevel = 'BATTERY_LEVEL',
  /** Cellular quality */
  CellularQuality = 'CELLULAR_QUALITY',
  /** Cellular strength */
  CellularStrength = 'CELLULAR_STRENGTH',
  /** Fullness of the container */
  Fullness = 'FULLNESS',
  /** Last synced at date */
  LastSyncedAt = 'LAST_SYNCED_AT',
  /** Name */
  Name = 'NAME',
  /** Service location */
  ServiceLocation = 'SERVICE_LOCATION',
}

/** Input to sort results of a query */
export type ContainerSiteSortInput = {
  /** Field to sort on */
  sortBy: ContainerSiteSort;
  /** Direction for the sort */
  sortDirection: SortDirection;
};

/** Data associated with the container site unassigned event */
export type ContainerSiteUnassignedEventData = {
  __typename?: 'ContainerSiteUnassignedEventData';
  /** Container site that the BinBar was unassigned from */
  previousContainerSite: ContainerSite;
};

/** Represents the Corgi algorithm */
export type CorgiAlgo = {
  __typename?: 'CorgiAlgo';
  /** The container cycles used to derive this calibration */
  containerCycles: Array<ContainerCycle>;
  /** The resultant weight adjustment factor */
  weightAdjustmentFactor: Scalars['Float']['output'];
};

/** Input for a Corgi algo */
export type CorgiAlgoInput = {
  /** The container cycles used to derive this calibration - for tracking */
  containerCycleIds: Array<Scalars['ID']['input']>;
  /** The resultant weight adjustment factor */
  weightAdjustmentFactor: Scalars['Float']['input'];
};

/** Input to create a new account */
export type CreateAccountInput = {
  /** Name of account */
  name: Scalars['String']['input'];
  /** Parent account id */
  parentAccountId?: InputMaybe<Scalars['ID']['input']>;
  /** Salesforce account id */
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  /** Slack webhook URL for account alerts */
  slackWebhookUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Input to create a new container site */
export type CreateContainerSiteInput = {
  /** Assigned driver id */
  assignedDriverId?: InputMaybe<Scalars['ID']['input']>;
  /** Commodity type dumped into the container */
  commodityType?: InputMaybe<Scalars['String']['input']>;
  /** Cost per run */
  costPerRun?: InputMaybe<CurrencyValueInput>;
  /** Empty container weight */
  emptyContainerWeight?: InputMaybe<WeightInput>;
  /** Maximum gross weight allowed in the container site. This value determines the estimated fullness percentage of the container */
  maxGrossWeight: WeightInput;
  /** Name of the container site */
  name: Scalars['String']['input'];
  /** Service location Id for the container site */
  serviceLocationId: Scalars['ID']['input'];
  /** Size of the container site in yards. This field is only used to help users keep track of containers */
  size: Scalars['Float']['input'];
};

/** Input to create a new service location */
export type CreateServiceLocationInput = {
  /** Account Id for the service location */
  accountId: Scalars['ID']['input'];
  /** Name of the service location */
  name: Scalars['String']['input'];
};

/** Input to create a new service request */
export type CreateServiceRequestInput = {
  /** Account id for the service request */
  accountId: Scalars['ID']['input'];
  /** Description of the service request */
  description: Scalars['String']['input'];
  /** Reason for the service request */
  reason: ServiceRequestReason;
  /** Subject of the service request */
  subject: Scalars['String']['input'];
  /** Type of service request */
  type: ServiceRequestType;
};

/** Input to create a new user event */
export type CreateUserEventInput = {
  /** Type of event triggered by the user */
  event: UserEventType;
  /** Additional data associated with the impersonate event */
  impersonateEventData?: InputMaybe<ImpersonateEventDataInput>;
};

/** Input to create a new user */
export type CreateUserInput = {
  /** Account roles for the user */
  accountRoles: Array<UserAccountRoleInput>;
  /** Email of the user */
  email: Scalars['String']['input'];
  /** Name of the user */
  name: Scalars['String']['input'];
};

/** Input to create a new weight ticket */
export type CreateWeightTicketInput = {
  /** The id of the container cycle that corresponds to this weight ticket */
  containerCycleId: Scalars['ID']['input'];
  /** The empty container weight */
  emptyContainerWeight: WeightInput;
  /** The gross weight from the weight ticket */
  grossWeight: WeightInput;
  /** The net weight from the weight ticket */
  netWeight: WeightInput;
  /** The tare weight from the weight ticket */
  tareWeight: WeightInput;
};

/** Represents the type of currency */
export enum CurrencyType {
  /** usa penny */
  UsaPenny = 'USA_PENNY',
}

/** Represents a currency value */
export type CurrencyValue = {
  __typename?: 'CurrencyValue';
  /** Amount value */
  amount: Scalars['Int']['output'];
  /** Currency type */
  currency: CurrencyType;
};

/** Input to represent a currency value */
export type CurrencyValueInput = {
  /** Amount value */
  amount: Scalars['Int']['input'];
  /** Currency type */
  currency: CurrencyType;
};

/** The data of a ledger instance */
export type Data = {
  __typename?: 'Data';
  /** Boot mode */
  bootMode: Scalars['String']['output'];
  /** Data thresholds */
  dataThresholds?: Maybe<DataThresholds>;
  /** Wake timing */
  wakeTiming?: Maybe<WakeTiming>;
};

/** Data thresholds */
export type DataThresholds = {
  __typename?: 'DataThresholds';
  /** Publish threshold */
  publishThresholdLbf: Scalars['Int']['output'];
  /** Save threshold */
  saveThresholdLbf: Scalars['Int']['output'];
};

/** Data thresholds input */
export type DataThresholdsInput = {
  /** Publish threshold */
  publishThresholdLbf: Scalars['Int']['input'];
  /** Save threshold */
  saveThresholdLbf: Scalars['Int']['input'];
};

/** Input to represent a range of time between two date times */
export type DateRangeInput = {
  /** End of desired time range */
  end?: InputMaybe<Scalars['DateTimeISO']['input']>;
  /** Start of desired time range */
  start?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

/** Information used to update a container site's weight calibration */
export type DeriveCorgiAlgoInput = {
  containerCycleIds: Array<Scalars['ID']['input']>;
};

/** A diagnostic record for a BinBar */
export type Diagnostic = {
  __typename?: 'Diagnostic';
  /** Related BinBar */
  binBar: BinBar;
  /** Cellular operator */
  cellularOperator?: Maybe<Scalars['String']['output']>;
  /** Id of the diagnostic record */
  id: Scalars['ID']['output'];
  /** Access technology */
  radioAccessTechnology?: Maybe<Scalars['String']['output']>;
  /** Date and time the diagnostic record was created */
  updatedAt: Scalars['DateTimeISO']['output'];
};

/** Document processor options for an account */
export type DocumentProcessor = {
  __typename?: 'DocumentProcessor';
  processorName: Scalars['String']['output'];
  /** Type of document processor */
  type: DocumentProcessorType;
  weightUnit?: Maybe<WeightUnit>;
};

/** Input for a document processor */
export type DocumentProcessorInput = {
  processorName: Scalars['String']['input'];
  /** Type of document processor */
  type: DocumentProcessorType;
  weightUnit?: InputMaybe<WeightUnit>;
};

/** Represents the type of document processor */
export enum DocumentProcessorType {
  /** Weight ticket processor */
  WeightTicket = 'WEIGHT_TICKET',
}

/** Represents the Duff algorithm */
export type DuffAlgo = {
  __typename?: 'DuffAlgo';
  bumperCalibration: Array<LutBumperValue>;
  bumperCalibrationDetails?: Maybe<CalibrationDetails>;
  encoder0Calibration: SecondOrderSystem;
  encoder0CalibrationDetails?: Maybe<CalibrationDetails>;
  encoder1Calibration: SecondOrderSystem;
  encoder1CalibrationDetails?: Maybe<CalibrationDetails>;
  zNaught: Scalars['Float']['output'];
};

/** Input to represent the Duff algorithm */
export type DuffAlgoInput = {
  /** Bumper calibration */
  bumperCalibration: Array<LutBumperValueInput>;
  /** Details for the bumper calibration */
  bumperCalibrationDetails?: InputMaybe<CalibrationDetailsInput>;
  /** Encoder 0 calibration */
  encoder0Calibration: SecondOrderSystemInput;
  /** Details for the encoder 0 calibration */
  encoder0CalibrationDetails?: InputMaybe<CalibrationDetailsInput>;
  /** Encoder 1 calibration */
  encoder1Calibration: SecondOrderSystemInput;
  /** Details for the encoder 1 calibration */
  encoder1CalibrationDetails?: InputMaybe<CalibrationDetailsInput>;
  /** zNaught for the algorithm */
  zNaught: Scalars['Float']['input'];
};

/** Represents the Eggy algorithm */
export type EggyAlgo = {
  __typename?: 'EggyAlgo';
  bumperCalibration: Array<LutBumperValue>;
  bumperCalibrationDetails?: Maybe<CalibrationDetails>;
  encoder0Calibration: FourthOrderSystem;
  encoder0CalibrationDetails?: Maybe<CalibrationDetails>;
  zNaught: Scalars['Float']['output'];
};

/** Input to represent the Eggy algorithm */
export type EggyAlgoInput = {
  /** Bumper calibration */
  bumperCalibration: Array<LutBumperValueInput>;
  /** Details for the bumper calibration */
  bumperCalibrationDetails?: InputMaybe<CalibrationDetailsInput>;
  /** Encoder 0 calibration */
  encoder0Calibration: FourthOrderSystemInput;
  /** Details for the encoder 0 calibration */
  encoder0CalibrationDetails?: InputMaybe<CalibrationDetailsInput>;
  /** zNaught for the algorithm */
  zNaught: Scalars['Float']['input'];
};

export type FirmwareConfig = {
  __typename?: 'FirmwareConfig';
  /** Maximum amount of time a BinBar should stay offline */
  maxOfflineSeconds: Scalars['Int']['output'];
  /** Minimum amount of time a BinBar should stay offline */
  minOfflineSeconds: Scalars['Int']['output'];
  /** Sample period in seconds */
  samplePeriodSeconds: Scalars['Int']['output'];
  /** Firmware version running on the BinBar */
  version: Scalars['Float']['output'];
};

export type ForceAlgo = AtlasAlgo | BaconAlgo | DuffAlgo | EggyAlgo;

/** Represents a data point used to graph data and show historical data */
export type ForcePost = {
  __typename?: 'ForcePost';
  /** The BinBar that reported this force point */
  binBar: BinBar;
  /** The force at the time of measurement */
  force: Scalars['Float']['output'];
  /** Id of the force post */
  id: Scalars['ID']['output'];
  /** The date and time at which the data point was measured */
  timestamp: Scalars['DateTimeISO']['output'];
};

/** Represents a data point used to graph data and show historical data */
export type ForcePostForceArgs = {
  unit: ForceUnit;
};

/** Represents options for different units of force */
export enum ForceUnit {
  /** Newtons */
  Newton = 'NEWTON',
  /** Pounds of force */
  PoundForce = 'POUND_FORCE',
}

/** ax^4 + bx^3 + cx^2 + dx + e */
export type FourthOrderSystem = {
  __typename?: 'FourthOrderSystem';
  a: Scalars['Float']['output'];
  b: Scalars['Float']['output'];
  c: Scalars['Float']['output'];
  d: Scalars['Float']['output'];
  e: Scalars['Float']['output'];
};

/** ax^4 + bx^3 + cx^2 + dx + e */
export type FourthOrderSystemInput = {
  a: Scalars['Float']['input'];
  b: Scalars['Float']['input'];
  c: Scalars['Float']['input'];
  d: Scalars['Float']['input'];
  e: Scalars['Float']['input'];
};

/** Data associated with the impersonate event */
export type ImpersonateEventData = {
  __typename?: 'ImpersonateEventData';
  /** Email of the user that was impersonated */
  email: Scalars['String']['output'];
};

/** Input to create a new user event */
export type ImpersonateEventDataInput = {
  /** Email of the user that was impersonated */
  email: Scalars['String']['input'];
};

/** A Particle.io ledger instance */
export type LedgerInstance = {
  __typename?: 'LedgerInstance';
  /** Data of the ledger instance */
  data: Data;
  /** Id of instance */
  id: Scalars['ID']['output'];
  /** Name of the ledger */
  name: Scalars['String']['output'];
  /** Scope of the ledger instance */
  scope: Scope;
};

export type LutBumperValue = {
  __typename?: 'LutBumperValue';
  force: Scalars['Float']['output'];
  z: Scalars['Float']['output'];
};

export type LutBumperValueInput = {
  force: Scalars['Float']['input'];
  z: Scalars['Float']['input'];
};

/** Data associated with the missing weight tickets alert */
export type MissingWeightTicketsAlertData = {
  __typename?: 'MissingWeightTicketsAlertData';
  /** The last container cycle with a weight ticket */
  lastCycleWithTicket?: Maybe<ContainerCycle>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Mutation used to acknowledge an alert */
  acknowledgeAlert: Alert;
  /** Mutation used to block a user */
  blockUser: User;
  /** Mutation used to create a new account */
  createAccount?: Maybe<Account>;
  /** Mutation used to create a new container site */
  createContainerSite: ContainerSite;
  /** Mutation used to create a new service location */
  createServiceLocation: ServiceLocation;
  /** Mutation used to create a new service request */
  createServiceRequest: ServiceRequest;
  /** Mutation used to create a new user */
  createUser: User;
  /** Mutation used to create a new user event */
  createUserEvent: UserEvent;
  /** Mutation used to create a weight ticket */
  createWeightTicket: WeightTicket;
  /** Mutation used to delete an account */
  deleteAccount?: Maybe<Scalars['Boolean']['output']>;
  /** Mutation used to delete a container site */
  deleteContainerSite?: Maybe<Scalars['Boolean']['output']>;
  /** Mutation used to delete a service location */
  deleteServiceLocation?: Maybe<Scalars['Boolean']['output']>;
  /** Mutation used to delete a user */
  deleteUser?: Maybe<Scalars['Boolean']['output']>;
  /** Mutation used to exclude a container cycle from WAF and reports */
  excludeContainerCycleFromWafAndReports: ContainerCycle;
  /** Mutation used to include a container cycle in WAF and reports */
  includeContainerCycleInWafAndReports: ContainerCycle;
  /** Mutation used to recalculate container cycle weights */
  recalculateContainerCycleWeights: ContainerCycle;
  /** Mutation used to send a phone verification code */
  sendPhoneVerificationCode: User;
  /** Mutation used to send another welcome email */
  sendWelcomeEmail: User;
  /** Mutation used to unblock a user */
  unblockUser: User;
  /** Mutation used to update an account */
  updateAccount?: Maybe<Account>;
  /** Mutation used to update alert subscriptions for an account */
  updateAccountAlertSubscriptions?: Maybe<Account>;
  /** Mutation used to update the document processors for an account */
  updateAccountDocumentProcessors?: Maybe<Account>;
  /** Mutation used to update a BinBar */
  updateBinBar: BinBar;
  /** Mutation used to update a BinBar's algorithm */
  updateBinBarAlgorithm: BinBar;
  /** Mutation used to update a BinBar's ledger instance */
  updateBinBarLedgerInstanceData: Scalars['Boolean']['output'];
  /** Mutation used to update a container cycle */
  updateContainerCycle: ContainerCycle;
  /** Mutation used to update a container site */
  updateContainerSite: ContainerSite;
  /** Mutation used to update the relationship between a container site and a BinBar given a container site Id and a optional BinBar Id */
  updateContainerSiteBinBar: ContainerSite;
  /** Mutation used to update an existing container site's calibration */
  updateContainerSiteCalibration: ContainerSite;
  /** Mutation used to update the driver assigned to a container site */
  updateContainerSiteDriver: ContainerSite;
  /** Mutation used to update the current user */
  updateCurrentUser: User;
  /** Mutation used to update alert subscriptions for a user */
  updateCurrentUserAlertSubscriptions?: Maybe<User>;
  /** Mutation used to update the current users theme */
  updateCurrentUserPreferences: User;
  /** Mutation used to update a service location */
  updateServiceLocation: ServiceLocation;
  /** Mutation used to update a user */
  updateUser: User;
  /** Mutation used to update a user's container access */
  updateUserContainerAccess?: Maybe<User>;
  /** Mutation used to update a weight ticket */
  updateWeightTicket: WeightTicket;
  /** Mutation used to get upload uris for installation photos */
  uploadContainerCycleInstallPhotos: Array<CloudStorageFileUpload>;
  /** Mutation used to get upload uris for removal photos */
  uploadContainerCycleRemovalPhotos: Array<CloudStorageFileUpload>;
  /** Mutation used to get upload uris for installation photos */
  uploadContainerSiteInstallPhotos: Array<CloudStorageFileUpload>;
  /** Mutation used to get an upload uri for a weight ticket */
  uploadWeightTicket: CloudStorageFileUpload;
  /** Mutation used to verify a phone verification code */
  verifyPhoneVerificationCode: User;
};

export type MutationAcknowledgeAlertArgs = {
  id: Scalars['ID']['input'];
  isAdminAlert?: Scalars['Boolean']['input'];
};

export type MutationBlockUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};

export type MutationCreateContainerSiteArgs = {
  input: CreateContainerSiteInput;
};

export type MutationCreateServiceLocationArgs = {
  input: CreateServiceLocationInput;
};

export type MutationCreateServiceRequestArgs = {
  input: CreateServiceRequestInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserEventArgs = {
  input: CreateUserEventInput;
};

export type MutationCreateWeightTicketArgs = {
  input: CreateWeightTicketInput;
};

export type MutationDeleteAccountArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteContainerSiteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteServiceLocationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationExcludeContainerCycleFromWafAndReportsArgs = {
  id: Scalars['ID']['input'];
};

export type MutationIncludeContainerCycleInWafAndReportsArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRecalculateContainerCycleWeightsArgs = {
  binBarId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type MutationSendWelcomeEmailArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUnblockUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateAccountArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAccountInput;
};

export type MutationUpdateAccountAlertSubscriptionsArgs = {
  id: Scalars['ID']['input'];
  input: Array<AlertSubscriptionInput>;
};

export type MutationUpdateAccountDocumentProcessorsArgs = {
  id: Scalars['ID']['input'];
  input: Array<DocumentProcessorInput>;
};

export type MutationUpdateBinBarArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBinBarInput;
};

export type MutationUpdateBinBarAlgorithmArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBinBarAlgorithmInput;
};

export type MutationUpdateBinBarLedgerInstanceDataArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLedgerInstanceDataInput;
};

export type MutationUpdateContainerCycleArgs = {
  id: Scalars['ID']['input'];
  input: UpdateContainerCycleInput;
};

export type MutationUpdateContainerSiteArgs = {
  id: Scalars['ID']['input'];
  input: UpdateContainerSiteInput;
};

export type MutationUpdateContainerSiteBinBarArgs = {
  binBarId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationUpdateContainerSiteCalibrationArgs = {
  binBarId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: CorgiAlgoInput;
};

export type MutationUpdateContainerSiteDriverArgs = {
  driverId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationUpdateCurrentUserArgs = {
  input: UpdateCurrentUserInput;
};

export type MutationUpdateCurrentUserAlertSubscriptionsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  containerSiteId?: InputMaybe<Scalars['ID']['input']>;
  input: Array<AlertSubscriptionInput>;
  serviceLocationId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateCurrentUserPreferencesArgs = {
  theme: UserTheme;
  weightDisplay: WeightDisplay;
  weightUnit: WeightUnit;
};

export type MutationUpdateServiceLocationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateServiceLocationInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserInput;
};

export type MutationUpdateUserContainerAccessArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserContainerAccessInput;
};

export type MutationUpdateWeightTicketArgs = {
  input: UpdateWeightTicketInput;
};

export type MutationUploadContainerCycleInstallPhotosArgs = {
  fileNames: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type MutationUploadContainerCycleRemovalPhotosArgs = {
  fileNames: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type MutationUploadContainerSiteInstallPhotosArgs = {
  fileNames: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type MutationUploadWeightTicketArgs = {
  containerCycleId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type MutationVerifyPhoneVerificationCodeArgs = {
  code: Scalars['String']['input'];
};

/** Data associated with the notes changed event */
export type NotesChangedEventData = {
  __typename?: 'NotesChangedEventData';
  /** New notes of the BinBar */
  newNotes?: Maybe<Scalars['String']['output']>;
  /** Previous notes of the BinBar */
  previousNotes?: Maybe<Scalars['String']['output']>;
};

/** Input to page a request for data into smaller chunks */
export type PageInput = {
  /** Page number */
  page: Scalars['Int']['input'];
  /** Page size */
  pageSize: Scalars['Int']['input'];
};

/** Input to paginate a request for data into smaller chunks */
export type PaginationInput = {
  /** Amount of records to return */
  limit: Scalars['Int']['input'];
  /** Zero based index to start */
  offset: Scalars['Int']['input'];
};

export type Query = {
  __typename?: 'Query';
  _config: ServerConfig;
  /** Query that returns the account associated with an Id */
  accountById: Account;
  /** Query that returns a list of document processors */
  allDocumentProcessors: Array<DocumentProcessor>;
  /** Query that returns the BinBar associated with an Id */
  binBarById: BinBar;
  /** Query that returns the container cycle associated with an Id */
  containerCycleById?: Maybe<ContainerCycle>;
  /** Query that returns the container site associated with an Id */
  containerSiteById: ContainerSite;
  /** Query that returns the current user */
  currentUser: User;
  /** Query that returns the calibration of a container site based on recent cycles */
  deriveCorgiAlgo: CorgiAlgo;
  /** Query that returns a list of accounts */
  searchAccounts: Array<Account>;
  /** Query that returns a count of accounts */
  searchAccountsCount: Scalars['Int']['output'];
  /** Query that returns a list of alerts */
  searchAlerts: Array<Alert>;
  /** Query that returns a count of alerts */
  searchAlertsCount: Scalars['Int']['output'];
  /** Query that returns a list of BinBars */
  searchBinBars: Array<BinBar>;
  /** Query that returns a count of BinBars */
  searchBinBarsCount: Scalars['Int']['output'];
  /** Query that returns a list of container sites */
  searchContainerSites: Array<ContainerSite>;
  /** Query that returns a count of container sites */
  searchContainerSitesCount: Scalars['Int']['output'];
  /** Query that returns a list of service locations for a user */
  searchServiceLocations: Array<ServiceLocation>;
  /** Query that returns a count of service locations for a user */
  searchServiceLocationsCount: Scalars['Int']['output'];
  /** Query that returns a list of users */
  searchUsers: Array<User>;
  /** Query that returns a count of users */
  searchUsersCount: Scalars['Int']['output'];
  /** Query that returns the service location associated with an Id */
  serviceLocationById: ServiceLocation;
  /** Query that returns the user associated with an email */
  userByEmail: User;
  /** Query that returns the user associated with an Id */
  userById: User;
  /** Query that returns a weight ticket associated with a specific Id */
  weightTicketByContainerCycleId?: Maybe<WeightTicket>;
};

export type QueryAccountByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBinBarByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryContainerCycleByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryContainerSiteByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDeriveCorgiAlgoArgs = {
  input: DeriveCorgiAlgoInput;
};

export type QuerySearchAccountsArgs = {
  isAdminQuery?: Scalars['Boolean']['input'];
  page?: PageInput;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: AccountSortInput;
};

export type QuerySearchAccountsCountArgs = {
  isAdminQuery?: Scalars['Boolean']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchAlertsArgs = {
  isAdminQuery?: Scalars['Boolean']['input'];
  page?: PageInput;
  sort?: AlertSortInput;
};

export type QuerySearchAlertsCountArgs = {
  isAdminQuery?: Scalars['Boolean']['input'];
};

export type QuerySearchBinBarsArgs = {
  isAdminQuery?: Scalars['Boolean']['input'];
  page?: PageInput;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: BinBarSortInput;
};

export type QuerySearchBinBarsCountArgs = {
  isAdminQuery?: Scalars['Boolean']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchContainerSitesArgs = {
  filter?: InputMaybe<Array<ContainerSiteFilterInput>>;
  group?: InputMaybe<ContainerSiteGroupInput>;
  isAdminQuery?: Scalars['Boolean']['input'];
  page?: PageInput;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: ContainerSiteSortInput;
};

export type QuerySearchContainerSitesCountArgs = {
  filter?: InputMaybe<Array<ContainerSiteFilterInput>>;
  isAdminQuery?: Scalars['Boolean']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchServiceLocationsArgs = {
  isAdminQuery?: Scalars['Boolean']['input'];
  page?: PageInput;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: ServiceLocationSortInput;
};

export type QuerySearchServiceLocationsCountArgs = {
  isAdminQuery?: Scalars['Boolean']['input'];
  search?: Scalars['String']['input'];
};

export type QuerySearchUsersArgs = {
  filter?: InputMaybe<Array<UserFilterInput>>;
  isAdminQuery?: Scalars['Boolean']['input'];
  page?: PageInput;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: UserSortInput;
};

export type QuerySearchUsersCountArgs = {
  filter?: InputMaybe<Array<UserFilterInput>>;
  isAdminQuery?: Scalars['Boolean']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryServiceLocationByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};

export type QueryUserByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWeightTicketByContainerCycleIdArgs = {
  containerCycleId: Scalars['ID']['input'];
};

/** Represents a data point used to graph data and show historical data */
export type RawPost = {
  __typename?: 'RawPost';
  /** The battery level at the time of measurement */
  batteryLevel?: Maybe<Scalars['Float']['output']>;
  /** The BinBar that reported this raw point */
  binBar: BinBar;
  /** Id of the raw post */
  id: Scalars['ID']['output'];
  /** The signal quality at the time of measurement */
  signalQuality?: Maybe<Scalars['Float']['output']>;
  /** The signal strength at the time of measurement */
  signalStrength?: Maybe<Scalars['Float']['output']>;
  /** The temperature at the time of measurement */
  temperature?: Maybe<Scalars['Float']['output']>;
  /** The date and time at which the data point was measured */
  timestamp: Scalars['DateTimeISO']['output'];
};

/** Represents options for resolution to display data */
export enum Resolution {
  /** Display one data point for every 1 day period */
  Day = 'DAY',
  /** Display all data points */
  Full = 'FULL',
  /** Display one data point for every 12 hour period */
  HalfDay = 'HALF_DAY',
  /** Display one data point for every 30 minute period */
  HalfHour = 'HALF_HOUR',
  /** Display one data point for every 1 hour period */
  Hour = 'HOUR',
  /** Display one data point for every 15 minute period */
  QuarterHour = 'QUARTER_HOUR',
}

/** The scope of a ledger instance */
export type Scope = {
  __typename?: 'Scope';
  /** Name of scope */
  name: Scalars['String']['output'];
  /** Type of scope */
  type: Scalars['String']['output'];
  /** Value of scope */
  value: Scalars['String']['output'];
};

/** ax^2 + bx + c */
export type SecondOrderSystem = {
  __typename?: 'SecondOrderSystem';
  a: Scalars['Float']['output'];
  b: Scalars['Float']['output'];
  c: Scalars['Float']['output'];
};

/** ax^2 + bx + c */
export type SecondOrderSystemInput = {
  a: Scalars['Float']['input'];
  b: Scalars['Float']['input'];
  c: Scalars['Float']['input'];
};

export type ServerConfig = {
  __typename?: 'ServerConfig';
  version: Scalars['String']['output'];
};

/** Represents a service location. A service location is a logical grouping of container sites based on geographical area */
export type ServiceLocation = {
  __typename?: 'ServiceLocation';
  /** Account of the service location */
  account: Account;
  /** A count of all container sites associated with the service location */
  containerSiteCount: Scalars['Int']['output'];
  /** A list of all container sites associated with the service location */
  containerSites: Array<ContainerSite>;
  /** Id of the service location */
  id: Scalars['ID']['output'];
  /** Name of the service location */
  name: Scalars['String']['output'];
};

/** Represents a service location. A service location is a logical grouping of container sites based on geographical area */
export type ServiceLocationContainerSitesArgs = {
  page?: PaginationInput;
};

/** Represents a user's container access for a service location */
export type ServiceLocationAccess = {
  __typename?: 'ServiceLocationAccess';
  /** The container sites for the container access policy */
  containerSites?: Maybe<Array<ContainerSiteAccess>>;
  /** The service location for the container access policy */
  serviceLocation: ServiceLocation;
};

export type ServiceLocationAccessInput = {
  /** Container site access policies for the service location */
  containerSites?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Service location id */
  id: Scalars['ID']['input'];
};

/** Represents the fields available to sort service locations */
export enum ServiceLocationSort {
  /** Account name */
  AccountName = 'ACCOUNT_NAME',
  /** Name */
  Name = 'NAME',
}

/** Input to sort results of a query */
export type ServiceLocationSortInput = {
  /** Field to sort on */
  sortBy: ServiceLocationSort;
  /** Direction for the sort */
  sortDirection: SortDirection;
};

/** Represents a service request */
export type ServiceRequest = {
  __typename?: 'ServiceRequest';
  /** Account for the service request */
  account: Account;
  /** Description of the service request */
  description: Scalars['String']['output'];
  /** Id of the service request */
  id: Scalars['ID']['output'];
  /** Reason for the service request */
  reason: ServiceRequestReason;
  /** Subject of the service request */
  subject: Scalars['String']['output'];
  /** Type of service request */
  type: ServiceRequestType;
  /** User that created the service request */
  user?: Maybe<User>;
};

/** Represents the reason for service request */
export enum ServiceRequestReason {
  /** Existing problem */
  ExistingProblem = 'EXISTING_PROBLEM',
  /** New problem */
  NewProblem = 'NEW_PROBLEM',
}

/** Represents the type of service request */
export enum ServiceRequestType {
  /** Feature request */
  FeatureRequest = 'FEATURE_REQUEST',
  /** Problem */
  Problem = 'PROBLEM',
  /** Question */
  Question = 'QUESTION',
}

/** Represents the sort direction for a query */
export enum SortDirection {
  /** Ascending */
  Asc = 'ASC',
  /** Descending */
  Desc = 'DESC',
}

/** Data associated with the status changed event */
export type StatusChangedEventData = {
  __typename?: 'StatusChangedEventData';
  /** New status of the BinBar */
  newStatus: BinBarStatus;
  /** Previous status of the BinBar */
  previousStatus: BinBarStatus;
};

/** Represents a subscriber to an alert */
export type Subscriber = {
  __typename?: 'Subscriber';
  /** The subscribed account */
  account?: Maybe<Account>;
  /** The subscribed user */
  user?: Maybe<User>;
};

/** Input to update an account */
export type UpdateAccountInput = {
  /** Name of account */
  name: Scalars['String']['input'];
  /** Parent account id */
  parentAccountId?: InputMaybe<Scalars['ID']['input']>;
  /** Salesforce account id */
  salesforceId?: InputMaybe<Scalars['String']['input']>;
  /** Slack webhook URL for account alerts */
  slackWebhookUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Input to update a BinBar's algorithm */
export type UpdateBinBarAlgorithmInput = {
  /** Calibration settings for duff algorithm */
  duffAlgorithm?: InputMaybe<DuffAlgoInput>;
  /** Ebox id of BinBar */
  eboxId: Scalars['String']['input'];
  /** Calibration settings for eggy algorithm */
  eggyAlgorithm?: InputMaybe<EggyAlgoInput>;
  /** Name of BinBar */
  name: Scalars['String']['input'];
  /** PCB id of BinBar */
  pcbId: Scalars['String']['input'];
};

/** Input to update a BinBar */
export type UpdateBinBarInput = {
  /** Account of BinBar */
  accountId?: InputMaybe<Scalars['ID']['input']>;
  /** Brain id of BinBar */
  brainId: Scalars['String']['input'];
  /** Name of BinBar */
  name: Scalars['String']['input'];
  /** Notes for the BinBar */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Status of the BinBar */
  status: BinBarStatus;
};

/** Input to update a container cycle */
export type UpdateContainerCycleInput = {
  costPerRun?: InputMaybe<CurrencyValueInput>;
  emptyContainerWeight?: InputMaybe<WeightInput>;
};

/** Input to update a container site */
export type UpdateContainerSiteInput = {
  /** Assigned driver id */
  assignedDriverId?: InputMaybe<Scalars['ID']['input']>;
  /** Commodity type dumped into the container */
  commodityType?: InputMaybe<Scalars['String']['input']>;
  /** Cost per run */
  costPerRun?: InputMaybe<CurrencyValueInput>;
  /** Empty container weight */
  emptyContainerWeight?: InputMaybe<WeightInput>;
  /** Maximum gross weight allowed in the container site. This value determines the estimated fullness percentage of the container */
  maxGrossWeight: WeightInput;
  /** Name of the container site */
  name: Scalars['String']['input'];
  /** Service location Id for the container site */
  serviceLocationId: Scalars['ID']['input'];
  /** Size of the container site in yards. This field is only used to help users keep track of containers */
  size: Scalars['Float']['input'];
};

/** Input to update the current user */
export type UpdateCurrentUserInput = {
  /** Phone number for the current user */
  phoneNumber: Scalars['String']['input'];
};

/** Input to update a BinBar's ledger instance */
export type UpdateLedgerInstanceDataInput = {
  /** Data thresholds */
  dataThresholds: DataThresholdsInput;
  /** Wake timing */
  wakeTiming: WakeTimingInput;
};

/** Input to update a service location */
export type UpdateServiceLocationInput = {
  /** Name of the service location */
  name: Scalars['String']['input'];
};

/** Input to update a user's container access policy */
export type UpdateUserContainerAccessInput = {
  /** Container access policy for a user */
  containerAccess: Array<ContainerAccessInput>;
};

/** Input to update a user */
export type UpdateUserInput = {
  /** Account roles for the user */
  accountRoles: Array<UserAccountRoleInput>;
  /** Name of the user */
  name: Scalars['String']['input'];
};

/** Input to update a weight ticket */
export type UpdateWeightTicketInput = {
  /** The id of the container cycle that corresponds to this weight ticket */
  containerCycleId: Scalars['ID']['input'];
  /** The empty container weight */
  emptyContainerWeight: WeightInput;
  /** The gross weight from the weight ticket */
  grossWeight: WeightInput;
  /** The net weight from the weight ticket */
  netWeight: WeightInput;
  /** The tare weight from the weight ticket */
  tareWeight: WeightInput;
};

/** The upload status of a file */
export enum UploadStatus {
  /** Failed */
  Failed = 'FAILED',
  /** Uploaded */
  Uploaded = 'UPLOADED',
  /** Uploading */
  Uploading = 'UPLOADING',
}

/** Represents a user that is associated with a account */
export type User = {
  __typename?: 'User';
  /** Account roles for the user */
  accountRoles: Array<UserAccountRole>;
  /** Alert subscriptions for the user */
  alertSubscriptions: Array<AlertSubscription>;
  /** Container access for the user */
  containerAccess: Array<ContainerAccess>;
  /** Email of the user */
  email: Scalars['String']['output'];
  /** If true, the user has alert subscriptions */
  hasAlertSubscriptions: Scalars['Boolean']['output'];
  /** Id of the user */
  id: Scalars['ID']['output'];
  /** If true, the user is blocked from accessing the application */
  isBlocked: Scalars['Boolean']['output'];
  /** Name of the user */
  name?: Maybe<Scalars['String']['output']>;
  /** Phone details for the user */
  phone?: Maybe<UserPhone>;
};

/** Represents a user that is associated with a account */
export type UserAlertSubscriptionsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  containerSiteId?: InputMaybe<Scalars['ID']['input']>;
  serviceLocationId?: InputMaybe<Scalars['ID']['input']>;
};

/** Represents a user that is associated with a account */
export type UserHasAlertSubscriptionsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  containerSiteId?: InputMaybe<Scalars['ID']['input']>;
  serviceLocationId?: InputMaybe<Scalars['ID']['input']>;
};

/** Represents an account role for a user */
export type UserAccountRole = {
  __typename?: 'UserAccountRole';
  /** Account for this account role */
  account: Account;
  /** Role for this account role */
  role: AccountRole;
};

/** Input to create a new user account role */
export type UserAccountRoleInput = {
  /** Account id for this account role */
  accountId: Scalars['ID']['input'];
  /** Role for this account role */
  role: AccountRole;
};

/** Represents a user event */
export type UserEvent = {
  __typename?: 'UserEvent';
  /** The date and time at which the event was triggered */
  createdAt: Scalars['DateTimeISO']['output'];
  /** Type of event triggered by the user */
  event: UserEventType;
  /** Additional data associated with the event */
  eventData?: Maybe<UserEventData>;
  /** Id of the user event */
  id: Scalars['ID']['output'];
  /** User that triggered the event */
  user: User;
};

export type UserEventData = ImpersonateEventData;

/** Represents the type of event triggered by a user */
export enum UserEventType {
  /** User impersonated another user */
  Impersonate = 'IMPERSONATE',
  /** User logged into the system */
  Login = 'LOGIN',
  /** User logged out of the system */
  Logout = 'LOGOUT',
  /** User exchanged a token with the authentication server */
  TokenExchange = 'TOKEN_EXCHANGE',
}

/** Represents the fields available to filter users */
export enum UserFilter {
  /** Account id of the user */
  AccountId = 'ACCOUNT_ID',
  /** Account role of the user */
  AccountRole = 'ACCOUNT_ROLE',
}

/** Input to filter results of a query */
export type UserFilterInput = {
  /** Field to filter by */
  filterBy: UserFilter;
  /** Value to filter by */
  value: Scalars['String']['input'];
};

/** Phone number details for a user */
export type UserPhone = {
  __typename?: 'UserPhone';
  /** If true, the phone number has been verified by the user */
  isVerified: Scalars['Boolean']['output'];
  /** Phone number of the user */
  number: Scalars['String']['output'];
};

/** Represents the fields available to sort users */
export enum UserSort {
  /** Email */
  Email = 'EMAIL',
  /** Name */
  Name = 'NAME',
}

/** Input to sort results of a query */
export type UserSortInput = {
  /** Field to sort on */
  sortBy: UserSort;
  /** Direction for the sort */
  sortDirection: SortDirection;
};

/** Represents the user's preferred theme */
export enum UserTheme {
  /** Dark theme */
  Dark = 'DARK',
  /** Light theme */
  Light = 'LIGHT',
}

/** Wake timing */
export type WakeTiming = {
  __typename?: 'WakeTiming';
  /** Max time offline */
  maxTimeOfflineSec: Scalars['Int']['output'];
  /** Min time offline */
  minTimeOfflineSec: Scalars['Int']['output'];
  /** Sample period */
  samplePeriodSec: Scalars['Int']['output'];
};

/** Wake timing input */
export type WakeTimingInput = {
  /** Max time offline */
  maxTimeOfflineSec: Scalars['Int']['input'];
  /** Min time offline */
  minTimeOfflineSec: Scalars['Int']['input'];
  /** Sample period */
  samplePeriodSec: Scalars['Int']['input'];
};

export type WeightAlgo = CorgiAlgo;

/** Represents options for different displays of weight */
export enum WeightDisplay {
  /** Gross */
  Gross = 'GROSS',
  /** Net */
  Net = 'NET',
}

/** Input to describe a weight value */
export type WeightInput = {
  /** The unit of measurement that the amount of weight uses */
  unit: WeightUnit;
  /** The amount of weight */
  value: Scalars['Float']['input'];
};

/** Represents a data point used to graph data and show historical data */
export type WeightPost = {
  __typename?: 'WeightPost';
  /** The force post used to calculate the weight */
  forcePost: ForcePost;
  /** Gross weight measured */
  grossWeight: Scalars['Float']['output'];
  /** Id of the weight post */
  id: Scalars['ID']['output'];
  /** Net weight measured.  Determined by using the empty weight of the container */
  netWeight: Scalars['Float']['output'];
  /** The date and time at which the data point was measured */
  timestamp: Scalars['DateTimeISO']['output'];
  /** The weight adjustment factor applied to the measured weight */
  weightAdjustmentFactor: Scalars['Float']['output'];
  /** The adjustment factor applied to the weight */
  weightAdjustmentFactorName: AdjustmentFactorName;
};

/** Represents a data point used to graph data and show historical data */
export type WeightPostGrossWeightArgs = {
  unit: WeightUnit;
};

/** Represents a data point used to graph data and show historical data */
export type WeightPostNetWeightArgs = {
  unit: WeightUnit;
};

/** Represents a weight ticket */
export type WeightTicket = {
  __typename?: 'WeightTicket';
  /** The weight ticket attachment */
  attachment?: Maybe<CloudStorageFile>;
  /** The container cycle that corresponds to this weight ticket */
  containerCycle: ContainerCycle;
  /** The gross weight (from the weight ticket, do not confuse this with the gross weight measured by BinBar) */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Id of the weight ticket */
  id: Scalars['ID']['output'];
  /** Has the weight ticket been fully processed */
  isProcessed: Scalars['Boolean']['output'];
  /** The net weight (from the weight ticket, which is gross-tare) */
  netWeight?: Maybe<Scalars['Float']['output']>;
  /** The status of the weight ticket */
  status: WeightTicketStatus;
  /** The tare weight (from the weight ticket, do not confuse this with the tare of the container on the BinBar) */
  tareWeight?: Maybe<Scalars['Float']['output']>;
};

/** Represents a weight ticket */
export type WeightTicketGrossWeightArgs = {
  unit: WeightUnit;
};

/** Represents a weight ticket */
export type WeightTicketNetWeightArgs = {
  unit: WeightUnit;
};

/** Represents a weight ticket */
export type WeightTicketTareWeightArgs = {
  unit: WeightUnit;
};

/** Represents the status of a weight ticket */
export enum WeightTicketStatus {
  /** Ticket was manually entered */
  ManuallyEntered = 'MANUALLY_ENTERED',
  /** Ticket needs a human review */
  NeedsReview = 'NEEDS_REVIEW',
  NoDocumentProcessor = 'NO_DOCUMENT_PROCESSOR',
  /** Ticket was processed successfully */
  Processed = 'PROCESSED',
  /** Ticket is processing */
  Processing = 'PROCESSING',
  /** Ticket was reviewed by a human */
  Reviewed = 'REVIEWED',
}

/** Represents options for different units of weight */
export enum WeightUnit {
  /** Kilograms */
  Kilogram = 'KILOGRAM',
  /** Long Tons (2240 lbs) */
  LongTon = 'LONG_TON',
  /** Pounds */
  Pound = 'POUND',
  /** Short Tons (2000 lbs) */
  ShortTon = 'SHORT_TON',
}
